//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DocumentDataField from "../components/DocumentDataField.vue";
import FilesManager from "../components/FilesManager.vue";
import DocumentsManager from "../components/DocumentsManager.vue";
import Alert from "../components/Alert.vue";

export default {
    components: {
        DocumentDataField,
        FilesManager,
        DocumentsManager,
        Alert
    },

    data() {
        return {
            documents: [],
            documents_loading: false,
            documents_loaded: false,

            document_id: "",
            document_name: "",
            document_is_locked: false,
            current_document: "",

            files_dialog: false,
            files_loading: false,

            details_dialog: false,
            details_loading: false,

            confirm_dialog: false,
            confirm_loading: false
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        groupDocuments(docs) {
            this.documents = [];

            for (let i = 0; i < docs.length; i++) {
                let ix = -1;

                for (let j = 0; j < this.documents.length; j++) {
                    if (this.documents[j][0].document_type === docs[i].document_type) {
                        ix = j;
                        break;
                    }
                }

                if (ix !== -1) {
                    this.documents[ix].push(docs[i]);
                } else {
                    this.documents.push([docs[i]]);
                }
            }
        },

        async sendDocumentsData() {
            if (this.sending === true) return;

            for (const document of this.documents) {
                for (const [_, v] of Object.entries(document)) {
                    if (
                        (v.document_type === "debtor_bik" ||
                            v.document_type === "debtor_esad" ||
                            v.document_type === "debtor_krz") &&
                        v.files_count <= 0
                    ) {
                        this.$message({
                            type: "error",
                            msg: `Dodaj przynajmniej jeden plik do dokumentu "${v.name}", aby przesłać wniosek do weryfikacji`
                        });
                        this.confirm_dialog = false;

                        return;
                    }

                    if (v.is_valid === false) {
                        this.$message({
                            type: "error",
                            msg: "Dokumenty, które próbujesz przesłać zawierają błędy"
                        });
                        this.confirm_dialog = false;

                        return;
                    }
                }
            }

            this.sending = true;

            try {
                this.$axios.$post(`/applications/${this.app._id}/confirm-documents`);

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Dane zostały zatwierdzone"
                });

                this.$router.push({
                    name: "application-processing"
                });

                this.confirm_dialog = false;
            } catch (error) {
                console.error(error);
            }
            this.sending = false;
        },

        async fetchData() {
            if (this.documents_loading === true) return;

            this.documents_loading = true;
            try {
                let keep_loading = true;
                let current_page = 1;
                let a = [];

                while (keep_loading) {
                    const data = await this.$axios.$get(
                        `/documents/?application=${this.app._id}&document_type=predefined&page=${current_page}&items_per_page=8`
                    );
                    if (data.pagination.current_page >= data.pagination.number_of_pages)
                        keep_loading = false;

                    current_page += 1;
                    a = a.concat(data.documents);
                }
                this.documents_loaded = true;

                this.groupDocuments(a);
            } catch (error) {
                console.error(error);
            }
            this.documents_loading = false;
        },

        openFilesDialog(doc) {
            this.files_dialog = true;
            this.document_id = doc._id;
            this.document_name = doc.name;
            this.document_is_locked = doc.is_locked;
        },

        openDetailsDialog(doc) {
            this.details_dialog = true;
            this.document_id = doc._id;
            this.document_name = doc.name;
            this.current_document = doc;
        },

        openConfirmDialog() {
            this.confirm_dialog = true;
        },

        handleUpdateDocument(doc) {
            let ix_grp = -1;
            let ix_ele = -1;

            for (let i = 0; i < this.documents.length; i++) {
                for (let j = 0; j < this.documents[i].length; j++) {
                    if (this.documents[i][j]._id === doc._id) {
                        ix_grp = i;
                        ix_ele = j;
                        break;
                    }
                }

                if (ix_grp !== -1 && ix_ele !== -1) {
                    break;
                }
            }

            if (ix_grp === -1 || ix_ele === -1) return;

            const item_copy = { ...this.documents[ix_grp][ix_ele] };

            this.documents[ix_grp].splice(ix_ele, 1, {
                ...item_copy,
                ...doc
            });
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        this.fetchData();

        this.$io.on("Document.updated", this.handleUpdateDocument);
    },

    beforeDestroy() {
        this.$io.off("Document.updated", this.handleUpdateDocument);
    },

    metaInfo: {
        title: "Dokumenty"
    }
};
