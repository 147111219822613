//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object
        }
    },

    data() {
        return {
            doc_types: ["debtor_esad", "debtor_bik", "debtor_krz"]
        };
    },

    methods: {
        showFilesAction() {
            this.$emit("show-files");
        },
        showDetailsAction() {
            this.$emit("show-details");
        }
    }
};
